import React from 'react';
import './Sidebar.css';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import profilePicture from '../assets/profile.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import welcomeImage from '../assets/gym.jpeg';
import welcome from '../assets/welcomeI.jpg';
import { useNavigate } from 'react-router-dom';
import GymCard from './GymCard';
import './GymCard.css';

// Define the type for the gym object
interface Gym {
  id: number;
  image: string;
  name: string;
  description: string;
  location: string;
}

const AddGym: React.FC = () => {
  const navigate = useNavigate(); // Call useNavigate hook

  // Sample data for gyms
  const gyms: Gym[] = [
    {
      id: 1,
      image: welcomeImage, // Placeholder image, replace with your actual gym images
      name: 'Power World',
      description: 'Fitness Centre',
      location: 'Colombo',
    },
    {
      id: 2,
      image: welcomeImage,
      name: 'Power World 2',
      description: 'Fitness Centre',
      location: 'Colombo',
    },
    {
      id: 3,
      image: welcomeImage,
      name: 'Power World 3',
      description: 'Fitness Centre',
      location: 'Colombo',
    },
  ];

  return (
    <React.Fragment>
      {/* Header with search, button, icons */}
      <div className="header">
        <input type="text" placeholder="Search" className="search-bar" />
        <div className="header-right">
          <button className="add-gym-button" onClick={() => navigate('/add-gym-business')}>
            ADD GYM
          </button>
          <FontAwesomeIcon icon={faBell} className="icon-bell" />
          <img src={profilePicture} alt="Profile" className="profile-picture" />
        </div>
      </div>

      {/* Welcome and User Info section */}
      <div className="flex justify-between mt-5">
        <div
          className="flex-1 bg-white bg-cover bg-center bg-no-repeat rounded-lg shadow-md ml-8 p-6 text-white"
          style={{ backgroundImage: `url(${welcome})` }}
        >
          <h2 className="text-2xl font-bold mb-4">WELCOME BACK.....</h2>
          <p className="text-lg">
            Welcome back, Admin! Ready to manage and take your operations to the next level? Let's get started!
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-md text-center w-64 ml-4 p-4">
          <p className="text-red-500 text-sm">Hi <span>User...</span></p>
          <img src={profilePicture} alt="Profile" className="w-14 h-14 rounded-full mx-auto my-2" />
          <p className="text-lg font-bold">MASTER</p>
          <p className="text-gray-700 mt-4">Active Gyms: 15</p>
          <p className="text-gray-700 mt-4">Revenue: LKR 50,000.00</p>
        </div>
      </div>

      {/* Gym Cards Section */}
      <div className="gym-cards-container">
        {gyms.map(gym => (
          <GymCard key={gym.id} gym={gym} />
        ))}
      </div>
    </React.Fragment>
  );
};

export default AddGym;
