import React from 'react';
import './Sidebar.css';
import { useNavigate } from 'react-router-dom';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import profilePicture from '../assets/profile.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './GymBranch.css';
import GymBranchCard from './GymBranchCard';
import welcomeImage from '../assets/gym.jpeg';

// Define the type for the gym object
interface Gym {
  id: number;
  image: string;
  name: string;
  description: string;
  location: string;
}

const GymBranch: React.FC = () => {
  const navigate = useNavigate(); // Call useNavigate hook

  // Sample data for gyms
  const gyms: Gym[] = [
    {
      id: 1,
      image: welcomeImage, // Placeholder image, replace with your actual gym images
      name: 'Power World',
      description: 'Fitness Centre',
      location: 'Colombo',
    },
    {
      id: 2,
      image: welcomeImage,
      name: 'Power World 2',
      description: 'Fitness Centre',
      location: 'Colombo',
    },
    {
      id: 3,
      image: welcomeImage,
      name: 'Power World 3',
      description: 'Fitness Centre',
      location: 'Colombo',
    },
  ];

  return (
    <div>
      {/* Header with search, button, icons */}
      <div className="header">
        <input type="text" placeholder="Search" className="search-bar" />
        <div className="header-right">
          <button className="add-gym-button" onClick={() => navigate('/add-branch')}>
            ADD BRANCH
          </button>
          <FontAwesomeIcon icon={faBell} className="icon-bell" />
          <img src={profilePicture} alt="Profile" className="profile-picture" />
        </div>
      </div>

      {/* Gym Info Section */}
      <div className="gym-info">
        <h2 className="gym-title">POWER WORLD GYM</h2>
        <div className="gym-cards">
          <div className="card">
            <h3>10</h3>
            <p>Branches</p>
          </div>
          <div className="card">
            <h3>10</h3>
            <p>Available Plans</p>
          </div>
          <div className="card">
            <h3>10</h3>
            <p>Total Revenue</p>
          </div>
          <div className="card">
            <h3>10 : 5</h3>
            <p>Gender Ratio (M : F)</p>
          </div>
        </div>
      </div>

      {/* Gym Cards Section */}
      <div className="gym-cards-container">
        {gyms.map((gym) => (
          <GymBranchCard key={gym.id} gym={gym} />
        ))}
      </div>
      {/* Your form design for adding a branch */}
    </div>
  );
};

export default GymBranch;
